<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="from"
          label="부터"
          type="number"
          @blur="setFrom"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          ref="to"
          v-model="to"
          label="까지"
          type="number"
          @blur="setTo"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="result"
          label="결과 유형"
          @blur="setResult"
        />
      </v-col>
      <!-- <v-col cols="1">
        <v-btn @click="minusType(scoreResultSetting - 1)"> 삭제 </v-btn>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    scoreResultSetting: Number,
  },
  data() {
    return {
      from: '',
      to: '',
      result: this.scoreResultSetting,
    }
  },
  methods: {
    setFrom() {
      this.$store.dispatch('qna/actSetScoreResult', {
        theme: this.$route.params.themeId,
        num: this.scoreResultSetting,
        type: 'SCORE',
        from: this.from,
      })
    },
    setTo() {
      if (this.from >= this.to) {
        alert('to 값이 더 커야합니다.')
        // this.$refs.to.focus()
        return false
      }
      // this.$store.dispatch('qna/fetchQs', {
      //   themeId: this.$route.params.themeId,
      // })
      this.$store.dispatch('qna/actSetScoreResult', {
        theme: this.$route.params.themeId,
        num: this.scoreResultSetting,
        type: 'SCORE',
        to: this.to,
      })
    },
    setResult() {
      this.$store.dispatch('qna/actSetScoreResult', {
        theme: this.$route.params.themeId,
        num: this.scoreResultSetting,
        type: 'SCORE',
        result: this.result,
      })
    },
    minusType(val) {
      const settingFrom = 'from' + this.scoreResultSetting
      const settingTo = 'to' + this.scoreResultSetting
      const settingResult = 'result' + this.scoreResultSetting
      localStorage.removeItem(settingFrom)
      localStorage.removeItem(settingTo)
      localStorage.removeItem(settingResult)

      this.$emit('minusType', val)
    },
  },
}
</script>

<style></style>
